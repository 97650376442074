
.App {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100vh;
  flex-direction: row;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 1);
}

.skill_icon {
  height: 60px;
  width: 60px;
  margin: 15px;
  margin-bottom: 0;
}

@media (max-width: 479.98px) {
  .skill_icon {
    height: 50px;
    width: 50px;
    margin: 18px;
    margin-bottom: 0;
  }
}

.dev_name {
  font-size: 5em !important;
}

@media (max-width: 479.98px) {
  .dev_name {
    font-size: 3em !important;
  }
}

.navbar-custom {
  font-size:large;
}

.navbar__link {
  margin-left: 0.75em;
  margin-right: 0.75em;
  font-size: 1em;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: .1em;
  text-indent: .3em;
  border-bottom: 3px solid transparent;
}

@media (max-width: 767.98px) {
  .tab-buttons {
    width: 65%; /* Sets the width to 75% */
    margin: 0 auto; /* Centers the parent container */
  }
}

.tab-buttons .btn {
  white-space: nowrap; /* Prevents text from wrapping */
}

@media (max-width: 479.98px) {
  .tab-buttons {
    width: 100%; /* Sets the width to 75% */
    margin: 0 auto; /* Centers the parent container */
    padding: 0px 12px;
  }
}

.tab-buttons .btn {
  white-space: nowrap; /* Prevents text from wrapping */
}



.buttonStyle {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  background-size: cover;
  border: none;
  cursor: pointer;
  background-color: transparent; /* Ensure background is transparent */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for transform and shadow */
}

.buttonStyle:hover {
  transform: scale(1.1); /* Scale the button to 110% of its original size */
}


.navbar__link::after {
  transition: all ease-in-out .2s;
  background: none repeat scroll 0 0;
  content: "";
  display: block;
  margin-top: 2px;
  height: 3px;
  width: 0;
}

 .navbar__link:hover::after {
  visibility: visible;
  width: 40%;
}

.navbar__link--active::after {
  transition: all ease-in-out .2s;
  width: 100%;
}

.navbar__link--active:hover::after {
  width: 100%;
}

.MainApp {
  margin-top: 8vh;
  height: 90vh;
  width: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: auto;
}

/* Custom scrollbar */
.main::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.main::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
  border-radius: 5px;
  background-color: transparent;
}

.main::-webkit-scrollbar-thumb {
  background: #888; /* Thumb color */
  border-radius: 5px; /* Rounded corners */
}

.main::-webkit-scrollbar-thumb:hover {
  background: #555; /* Change color on hover */
}

.section-content-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.Typewriter {
  display: inline-block;
}

.Typewriter__wrapper {
  font-size: xx-large;
  font-weight: bold;
}

.Typewriter__cursor {
  font-size: xx-large;
  font-weight: bold;
}

section.timeline-card-content {
  justify-content: center;
}

section.timeline-card-content p.card-title {
  font-size: 1.5em;
  margin-top: 25px;
}

section.timeline-card-content p.card-sub-title {
  font-size: 1.25em;
  margin-top: 10px;
}

section.timeline-card-content div.card-description > p {
  font-size: 1.1em;
  margin-top: 10px;
  font-weight: 500;
}

.social {
  position: relative;
  margin-top: 60px;
}


@media only screen and (min-width: 768px) and (max-width: 992px) {

  .navbar__link {
    margin-left: 0.4em;
    margin-right: 0.4em;
    font-size: 0.9em;
    cursor: pointer;
    letter-spacing: .1em;
    text-indent: .2em;
  }
}

@media (max-width: 768px) {

  .navbar__link::after {
    background: none;
    content: "";
    display: none;
    margin-top: 0;
    height: 0;
    width: 0;
  }

  .navbar__link--active::after {
    width: 0;
  }
  
  .navbar__link--active:hover::after {
    width: 0;
  }

  .navbar__link:hover::after {
    width: 0;
  }
}

.header {
  font-size: 3em;
  font-weight: bold;
  margin-bottom: 25px;
}

.responsive-container {
  display: flex;
  flex-direction: column-reverse;
}

.responsive-col {
  width: 100%; /* Makes sure each column takes full width on small screens */
}

/* Media query for larger screens - adjust '768px' based on your design's breakpoint */
@media (min-width: 768px) {
  .responsive-container {
    flex-direction: row;
  }

  .responsive-col {
    /* Optional: define width for larger screens if you want unequal columns */
    width: 50%; /* Adjust or remove this line based on your layout needs */
  }
}

/* Add this to your CSS file */
.custom-modal-size {
  max-width: 65% !important; /* or any specific size you prefer */
}

.modal-body {
  padding: 0 !important;
}

.modal-footer {
  padding: 0 !important;
  border: none !important
}

.modal-header {
  padding: 10px 20px !important;
  border: none !important
}

.modal-content {
  background-color: rgb(42 43 45) !important;
}



